/*-----------------------------------------------
|   Form
-----------------------------------------------*/

.search {
  padding: 10px 20px 10px 74px;
  color:rgba(18,72,56,0.50);
  border: 1px solid rgba(18, 72, 56, 0.70);
  border-radius: 40px;
  outline: none !important;
  box-shadow: none;
  &:focus{
    border: 1px solid map-get($theme-colors,'success');
    color: map-get($theme-colors,'success');
    box-shadow: inset 0 1px 2px rgba(18, 72, 56,.25),0 0 0 .25rem rgba(18, 72, 56, .3);
  }
}
.search::placeholder{
  color:rgba(18,72,0,0.50);
}

.search-icon{
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  width: fit-content;

  span {
    font-size: 24px;
    color: map-get($theme-colors, 'success');
    opacity: 60%;
  }
}

.email-input {
  padding: 12px 25px;
  border-radius: 40px !important;
  border: 2px solid rgba(18, 72, 56, 0.50);
  color: rgba(18, 72, 56, 0.50);
  font-size: map-get($font-sizes, 8 );
  outline: none;
  &:focus {
    border: 2px solid map-get($theme-colors,'success');
    color: map-get($theme-colors,'success');
    box-shadow: inset 0 1px 2px rgba(18, 72, 56,.25),0 0 0 .25rem rgba(18, 72, 56, .3);
  }
  &::placeholder{
    color: rgba(18, 72, 56, 0.50);
    font-size: map-get($font-sizes, 8 );;
  }
}