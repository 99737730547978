// gray-color-variables
$white: #fff !default;
$gray-100: #f9fafd !default;
$gray-200: #edf2f9 !default;
$gray-300: #d8e2ef !default;
$gray-400: #b6c1d2 !default;
$gray-500: #9da9bb !default;
$gray-600: #748194 !default;
$gray-700: #5e6e82 !default;
$gray-800: #4d5969 !default;
$gray-900: #535353 !default;
$gray-1000: #2F2F2F !default;
$gray-1100: #333 !default;
$black: #000 !default;

// gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
  '1000': $gray-1000,
  '1100': $gray-1100,
) !default;

// color-variables
$blue: #2c7be5 !default;
$indigo: #727cf5 !default;
$purple: #6b5eae !default;
$pink: #ff679b !default;
$red: #e63757 !default;
$orange: #E3A476 !default;
$yellow: #FFDA91 !default;
$green: #124838 !default;
$teal: #02a8b5 !default;
$cyan: #27bcfd !default;

// theme-color-variables
$primary: $gray-1100 !default;
$secondary: $orange !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1000 !default;

// theme-color-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;

// brand-color-variables
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333333 !default;
$youtube: #ff0001 !default;

// brand-color-map
$brand-colors:(
  'facebook': $facebook,
  'google-plus': $google-plus,
  'twitter': $twitter,
  'linkedin': $linkedin,
  'youtube': $youtube,
  'github': $github,
) !default;

// icon-circle-color-map
$icon-circle-colors: (
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
) !default;

// scss-docs-start theme-text-variables
$primary-text-emphasis: shade-color($primary, 25%) !default;
$secondary-text-emphasis: shade-color($secondary, 20%) !default;
$success-text-emphasis: shade-color($success, 35%) !default;
$info-text-emphasis: shade-color($info, 35%) !default;
$warning-text-emphasis: shade-color($warning, 30%) !default;
$danger-text-emphasis: shade-color($danger, 20%) !default;
$light-text-emphasis: shade-color($light, 60%) !default;
$dark-text-emphasis: tint-color($dark, 55%) !default;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: tint-color($primary, 82%) !default;
$secondary-bg-subtle: tint-color($secondary, 82%) !default;
$success-bg-subtle: tint-color($success, 85%) !default;
$info-bg-subtle: tint-color($info, 82%) !default;
$warning-bg-subtle: tint-color($warning, 80%) !default;
$danger-bg-subtle: tint-color($danger, 82%) !default;
$light-bg-subtle: tint-color($light, 70%) !default;
$dark-bg-subtle: shade-color($dark, 30%) !default;
// scss-docs-end theme-bg-subtle-variables

$body-color: $gray-700 !default;
$body-bg: $gray-200 !default;

$body-secondary-color: $gray-900 !default;
$body-secondary-bg: $gray-300 !default;

$body-tertiary-color: $gray-600 !default;
$body-tertiary-bg: $gray-100 !default;

$body-quaternary-bg: $white !default;

$body-emphasis-color: $black !default;
$body-emphasis-bg: $white !default;