
/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */

.text-smallcaps {
  font-variant: small-caps;
}
.text-superscript {
  vertical-align: super;
}

/*-----------------------------------------------
|   Error Page
-----------------------------------------------*/
.fs-error {
  font-size: 7rem;
  @include media-breakpoint-up(sm) {
    font-size: 10rem;
  }
}
