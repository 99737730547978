.review-card{
    border-radius: 20px;
    background: rgba(255, 217, 145, 0.50);
    display: flex;
    padding: 24px 32px 24px 24px;
    align-items: flex-end;
    margin-bottom: 29px;
    gap: 24px;

    &:last-child {
        margin-bottom: 0px;
    }
}